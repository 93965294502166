<template>
<div v-if=false></div>
</template>
<script>
    export default {
        name: "NotFound",
        mounted: function() {
            if (this.$store.getters.isLoggedIn) {
                this.$router.push({name: 'OrderList'})
            } else {
                this.$router.push({name: 'login'})
            }
        }
    }
</script>